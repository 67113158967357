.franchise-custom-tactic {
  position: relative;
  .delete-button {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    z-index: 10;
  }
}

.delete-button {
  z-index: 10;
  background: none;
  border: none;
  padding: 0.5rem;
  cursor: pointer;

  .fa-trash {
    color: #dc3545;
  }

  &:hover {
    opacity: 0.8;
    .fa-trash {
      color: #FFFFFF;
    }
  }

  &:disabled, &:disabled:hover {
    cursor: default;
    opacity: 0.4;
    .fa-trash {
      color: #FFFFFF;
    }
  }
}
