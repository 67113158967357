.campaign-config-item {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 1rem;
    column-gap: 1rem;
    border-radius: 5px;
    border: 1px solid var(--brandPrimaryColor);
    padding: 0.5rem 1.5rem;
    font-size: 14px;
    min-height: 55px;

    .title {
        font-weight: bold;
        font-family: var(--robotoBold);
    }

    .actions {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 1rem;
        flex-grow: 1;
    }
}