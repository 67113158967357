.icon-button {
  background: none;
  border: none;

  i {
    font-size: 1.2rem;
  }

  &.edit {
    color: var(--brandSecondaryColor);

    &:hover {
      color: #FFFFFF;
    }
  }
}
