@mixin btn-primary($color) {
    &:hover {
        color: $color;
        background-color: transparent;
        border: 1px solid $color;
    }

    color: white;
    background-color: $color;
}

@mixin btn-secondary($color) {
    &:hover {
        color: white;
        background-color: $color;
    }

    color: $color;
    background-color: transparent;
    border: 1px solid $color;
}

.dm-button {
    color: white;
    font-weight: bold;
    font-family: var(--robotoBold);
    font-size: 12px;
    width: 150px;
    height: 40px;
    border-radius: 3px;
    border: none;

    &.disabled,
    &:disabled {
        border: none !important;
        color: white !important;
        background-color: var(--disabled) !important;
    }

    &.primary {
        @include btn-primary(var(--brandPrimaryColor));
    }

    &.outline-primary {
        @include btn-secondary(var(--brandPrimaryColor));
    }

    &.secondary {
        @include btn-primary(var(--brandSecondaryColor));
    }

    &.outline-secondary {
        @include btn-secondary(var(--brandSecondaryColor));
    }

    &.orange {
        @include btn-primary(var(--orange));
    }

    &.outline-orange {
        @include btn-secondary(var(--orange));
    }

    &.danger {
        @include btn-primary(var(--danger));
    }

    &.outline-danger {
        @include btn-secondary(var(--danger));
    }
}
