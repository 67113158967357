.budget-plans-table {
    font-size: 15px;

    th,
    td {
        font-size: 15px !important;
    }

    .status {
        text-transform: uppercase;

        &.submitted {
            color: #4ecff6;
        }

        &.approved {
            color: #005cc1;
        }

        &.rejected {
            color: #ff4502;
        }

        &.not-submitted {
            color: #ffc107;
        }

        &.draft {
            color: var(--neutral);
        }
    }

    .budget-plan-link {
        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }

        color: var(--linkColor);
    }

}

// .css-1s2u09g-control{
//     width: 185%!important;
// }
// .css-b62m3t-container {
//     width: 50%!important;
// }
.css-b62m3t-container{
    width: 100%;
    max-width: 400px;
}
.css-7pwich-Input{
    width: 250px;
}
.css-1y2qbuw{
    width: 250px;
}
