.mailing-plan-rule {
    display: flex;
    align-items: center;
    font-size: 12px;
    margin-bottom: 1rem;
    margin-right: 8px;
    margin-left: 8px;

    i {
        font-size: 10px;
        margin-right: 8px;
    }

    p {
        margin: 0;
    }

    &.completed {
        color: var(--brandPrimaryColor);
    }

    &.error {
        color: var(--danger);
    }

    &.warning {
        color: var(--warning);
    }
}