.dashboard-layout {
    display: grid;
    grid-template: 1fr / 1fr 3fr;

    .dashboard-overview {
        padding: 0 1rem;
        min-height: 110px;
    }

    .dashboard-navigation {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        column-gap: 1.25rem;
        row-gap: 1rem;
        padding: 0 1rem;
        height: fit-content;
    }

    .dashboard-content {
        padding: 0 1rem;
        margin-top: 2rem;
        grid-column: 1 / 3;
    }
}