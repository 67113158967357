.overdue-lockdate-input
{
	color: orange;
    border-color: orange;
	background-color: #f5e3d0 !important;
}

.overdue-icon
{
	color: orange !important;
	border-color: orange !important;
}