.campaign-config-wizard {
    .modal-dialog {
        max-width: 70% !important;
        height: 80% !important;
    }

    .modal-content {
        min-height: 100%;
        padding: 1rem 0;
    }
    
    .modal-header,
    .modal-body,
    .modal-footer {
        margin: 0 4rem;
    }

    .modal-header {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;

        i {
            font-size: 2.5rem;
            position: absolute;
            top: 1.5rem;
            right: 0;
            color: #585858;
            cursor: pointer;
        }
    }

    .modal-footer {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.campaign-config-modal-title {
    color: black;
    font-size: 24px;
    font-weight: bold;
    font-family: var(--robotoBold);
}

.campaign-config-modal-subtitle {
    color: var(--brandPrimaryColor);
    font-size: 14px;
    font-weight: bold;
    font-family: var(--robotoBold);
}

.campaign-config-wizard-steps {
    display: flex;
    flex-wrap: wrap;
    column-gap: 1.5rem;
    justify-content: center;
}

.campaign-config-step-indicator {
    width: 12px;
    height: 12px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;

    &.completed {
        color: var(--brandPrimaryColor);
    }

    &.active {
        color: var(--brandPrimaryColor);
    }
}