.brochure-selection {
    display: flex;
    flex-direction: column;
    column-gap: 1rem;
    align-items: center;
}

.brochure-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 1.25rem;
    column-gap: 1.25rem;
    padding: 8px;
    margin-top: 1rem;
    overflow: auto;
}

.brochure-item {
    cursor: pointer;

    &.blured {
        -webkit-filter: opacity(0.5);
        filter: opacity(0.5);
    }
}

.brochure-item-image {
    height: 40px;
    width: 70px;

    &.selected {
        border: 2px solid #fd681d;
    }
}

.brochure-item-expanded {
    flex-grow: 1;
}

.brochure-item-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 4rem;
    background-color: #dbdbdb;
    color: #959595;
}

.brochures-counter {
    text-align: center;
    font-size: 16px;
    color: #818181;
    font-weight: bold;
    font-family: var(--robotoBold);
    margin-bottom: 1.5rem;

    &.selected {
        color: #fd681d;
    }
}