.multi-select-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: fit-content;

    i {
        margin-left: 0.5rem;
    }
}

.multi-select-tag {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    padding: 0.25rem 1rem;
    background-color: var(--brandPrimaryColor);
    font-size: 12px;
    border-radius: 20px;

    i {
        cursor: pointer;
        margin-left: 0.5rem;
    }
}

@mixin multi-options-field {
    height: fit-content;
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    row-gap: 0.5rem;
    flex-wrap: wrap;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    padding: .375rem .75rem;
    cursor: pointer;
}

.multi-select-button-options {
    @include multi-options-field();
}

.multi-select-input-options {
    display: none;
}

.multi-select-input-field-container {
    position: relative;

    &:focus {
        .multi-select-input-options {
            width: 100% !important;
            display: block !important;
            position: absolute;
            background-color: white;
            border: 1px solid #ced4da;
            border-radius: 0.25rem;
            z-index: 100;
            max-height: 250px;
            overflow: hidden;
            overflow-y: scroll;
    
            .multi-select-option {
                padding: 0.25rem 0.5rem;
    
                &:hover {
                    cursor: pointer;
                    background-color: rgb(210, 229, 255);
                }
            }
        }
    }
}

.multi-select-input-field {
    @include multi-options-field();
    display: flex;
    align-items: center;
    width: 100%;
    padding-right: 1.5rem;

    i.input-icon {
        position: absolute;
        right: 10px;
    }
}