.add-button {
    color: var(--brandPrimaryColor);
    border: 1px solid var(--brandPrimaryColor);
    background: transparent;
    padding: 0.75rem 1.5rem;
    border-radius: 5px;

    &:hover {
        background-color: var(--brandPrimaryColor);
        color: white;
    }
}