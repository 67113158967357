.confirmation-modal {
    &.confirmation-text-centered {
        text-align: center;

        .alert-heading.h4 {
            width: 100%;
        }
    }

    .modal-header {
        padding: 1.5rem 1rem;
        border-bottom: none;
    }

    .alert-heading { 
        font-size: 18px !important;
        font-weight: bold !important;
        font-family: var(--robotoBold) !important;
    }
}