.order-item {
    display: flex;
    column-gap: 0.5rem;
    padding: 0.25rem;
    align-items: center;
}

.order-position {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    font-size: 12px;
    color: var(--brandPrimaryColor);
    border: 1px solid var(--brandPrimaryColor);
    border-radius: 3px;
}

.order-label {
    flex-grow: 1;
    display: flex;
    align-items: center;
    padding: 0.35rem 1rem;
    font-size: 12px;
    border: 1px solid #dee2e6;
    border-radius: 3px;
    column-gap: 0.75rem;

    i {
        font-size: 14px;
        color: var(--brandPrimaryColor);
    }
}
