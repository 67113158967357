.rules-config-item {
    border-radius: 5px;
    border: 1px solid #d5d5d5;
    padding: 0.75rem 1.5rem;
    font-size: 14px;
    min-height: 55px;
    
    &.active {
        border: 1px solid var(--brandPrimaryColor);
    }

    .rule-name {
        font-weight: bold;
        font-family: var(--robotoBold);
    }
}