.mailing-plan-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.mailing-plan-actions { 
    display: flex;
    align-items: center;
    column-gap: 1rem;
}

.mailing-plan-override {
    font-size: 12px;
}

.mailing-plan-id {
    font-size: 14px;
    font-weight: bold;
    font-family: var(--robotoBold);
}

.mailing-plan-info {
    display: flex;
    column-gap: 2rem;
    font-size: 14px;
}

.mailing-plan-total-spend {
    font-size: 14px;
    color: var(--brandPrimaryColor);
}