.mailing-plans-table {
    .mailing-plan-link {
        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }

        color: var(--linkColor);
    }

    table {
        tbody td {
            .create-plan-btn {
                font-size: 11px;
                color: var(--brandPrimaryColor);
                background-color: white;
                border: 1px solid var(--brandPrimaryColor);
                border-radius: 15px;
                padding: 8px 16px;
                
                &:hover {
                    color: white;
                    background-color: var(--brandPrimaryColor);
                    text-decoration: none;
                    cursor: pointer;
                }
            }
        }
    }
}

.mailing-plan-filters {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    column-gap: 1.5rem;
    margin-bottom: 1rem;

    .filter {
        width: 200px;
        max-width: 200px;
    }
}