.brochure-config-modal {
    .modal-dialog {
        max-width: 80% !important;
        height: 80% !important;
    }

    .modal-body {
        padding: 0;
    }
    
    .config-fields-container {
        display: flex;
        column-gap: 1rem;
        row-gap: 1rem;
        justify-content: center;
        align-items: flex-start;
        margin: 0.5rem 1rem;
    }

    @media (max-width: 1125px) {
        .config-fields-container {
            flex-direction: column !important;
            align-items: center !important;
            row-gap: 3rem;
        }
    }
}