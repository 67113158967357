.mailing-plan-submit-confirmation {
    .modal-dialog {
        max-width: 50% !important;
        height: 80% !important;
    }

    .modal-header {
        justify-content: flex-end;
        border-bottom: none;

        i {
            font-size: 2.5rem;
            top: 2rem;
            right: 2rem;
            color: #585858;
            cursor: pointer;
        }
    }

    .confirmation-text {
        max-width: 70%;
        text-align: center;
        font-size: 16px;
    }
}