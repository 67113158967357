.export-btn {
    font-weight: bold;
    font-family: var(--robotoBold);
    font-size: 12px;
    width: 85px;
    height: 30px;
    border-radius: 6px;
    color: var(--orange);
    background-color: transparent;
    border: 1px solid var(--orange);

    &.disabled {
        border: none !important;
        color: white !important;
        background-color: var(--disabled) !important;
    }

    &:hover {
        color: white;
        background-color: var(--orange);
    }
}