.lmpbp-comments-modal {
    .modal-dialog {
        max-width: 50% !important;
        height: 80% !important;

        .modal-content {
            padding: 2rem;
        }
    }

    .title {
        color: var(--brandPrimaryColor);
        font-size: 24px;
        font-family: var(--robotoBold);
        text-align: center;
    }

    .comments {
        min-height: 100px;
    }
}
