.notification-popup {
    position: fixed;
    top: 50px;
    right: 0;
    min-width: 350px;
    max-width: 350px;
    width: 350px;

    .alert-heading {
        font-size: 18px !important;
        font-weight: bold !important;
        font-family: var(--robotoBold) !important;
    }
}