.franchise-info-container {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    padding-bottom: 0.5rem;
    
    .info-group {
        display: flex;
        flex-direction: column;

        .info-label {
            font-size: 10px;
        }

        .info-value {
            font-size: 14px;
        }
    }
}

.franchise-info-confirmation-container {
    padding: 0.5rem 0;

    .confirm-info-title {
        font-size: 12px;

        &.error {
            color: var(--danger);
        }
    }

    .confirm-info-options {
        display: flex;
        justify-content: space-between;
        margin-top: 0.25rem;

        .confirm-option {
            display: flex;
            align-items: center;
            font-size: 12px;
        }
    }
}