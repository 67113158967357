.panel-border {
    border-top: 3px solid var(--brandSecondaryColor);
    border-radius: 3px;
}

.panel-header {
    font-size: 14px;
    font-weight: bold;
    font-family: var(--robotoBold);
    padding: 0.30rem 1.25rem;
}