.coupon-item-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 1.5rem;
    height: 100%;

    .coupon-item-title {
        text-overflow: ellipsis;
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
    }
}
