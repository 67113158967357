.discount-item-card {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--brandPrimaryColor);
    height: 60px;
    width: 200px;
    padding: 1rem;

    .output-text {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px;
        white-space: nowrap;
    }
}