.monthly-input-container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    border: 1px solid white;

    .monthly-input-header {
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid white;
        width: 100%;
        text-align: center;
        color: white;
        background-color: #575757;
        height: 24px;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: normal;
        -webkit-font-smoothing: antialiased;
        -webkit-font-smoothing: subpixel-antialiased;
        font-family: 'Roboto-Regular', system-ui, sans-serif;
    }

    .monthly-input-field {
        width: 100%;

        .disabled {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #ebebeb;
            border-radius: .25rem;
            font-size: 15px;
            font-family: var(--robotoBold);
            height: 60px !important;
        }

        select, input {
            height: 60px !important;
            font-size: 15px;
        }
    }
}