.tactics-total-table {
    width: 100%;

    td {
        text-align: center;
        background-color: #ebebeb;
        height: 64px;
        width: 105px;
        max-width: 105px;
        border: 2px solid white;
        border-radius: 0.5rem;
        font-size: 15px;
        font-family: var(--robotoBold);
        text-overflow: ellipsis;
        overflow: hidden;

        &:last-child {
            background-color: var(--brandPrimaryColor03);
        }
    }
}

.tactics-total-label {
    position: absolute;
    top: 25px;
    left: -40px;
    text-transform: uppercase;
    font-size: 12px;
    font-family: var(--robotoBold);
}