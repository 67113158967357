.font-family-bold {
    font-family: var(--robotoBold) !important;
}

.font-size-11 {
    font-size: 11px !important;
}

.font-size-12 {
    font-size: 12px !important;
}

.font-size-13 {
    font-size: 13px !important;
}

.font-size-14 {
    font-size: 14px !important;
}

.font-size-15 {
    font-size: 15px !important;
}

.font-size-16 {
    font-size: 16px !important;
}

.font-size-18 {
    font-size: 18px !important;
}

.font-size-20 {
    font-size: 20px !important;
}

.font-size-24 {
    font-size: 24px !important;
}

.font-size-30 {
    font-size: 30px !important;
}

.font-size-32 {
    font-size: 32px !important;
}

.font-size-36 {
    font-size: 36px !important;
}

.column-gap-1 {
    column-gap: 1rem;
}

.column-gap-2 {
    column-gap: 2rem;
}

.column-gap-4 {
    column-gap: 4rem;
}

.row-gap-1 {
    row-gap: 1rem;
}

.row-gap-2 {
    row-gap: 2rem;
}

.brand-primary-color {
    color: var(--brandPrimaryColor);
}

.cursor-pointer {
    cursor: pointer;
}

.error-msg {
    font-size: 12px;
    color: #ed0707;
}

.image-fill {
    object-fit: fill;
}

.image-cover {
    object-fit: cover;
}

.image-contain {
    object-fit: contain;
}

.flex-grow-2 {
    flex-grow: 2 !important;
}

.minw-fit-content {
    min-width: fit-content !important;
}

.minh-fit-content {
    min-height: fit-content !important;
}

.maxw-300px {
    max-width: 300px !important;
}

.maxw-75 {
    max-width: 75% !important;
}

.maxw-50 {
    max-width: 50% !important;
}

.maxw-30 {
    max-width: 30% !important;
}

.maxw-25 {
    max-width: 25% !important;
}

.p-abs-top-1 {
    top: 0.5rem;
}

.c-primary {
    color: var(--primary) !important;
}

.c-success {
    color: var(--success) !important;
}

.c-info {
    color: var(--info) !important;
}

.c-danger {
    color: var(--danger) !important;
}

.c-warning {
    color: var(--warning) !important;
}

.c-neutral {
    color: var(--neutral) !important;
}

.h-fit-content {
    height: fit-content !important;
}

.hidden { 
    display:none!important; 
}

.tactic-lock-date {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  
    &.warning {
        color: var(--warning);
    }
}

.save-license-button {
   width: 80px;
}

.coupon-exp-date {
    margin-bottom: -26px
}
.toggle-reminder {
    margin: 5px;
}
.toggle-header {
   font-size: 12px;
    font-weight: bold;
    font-family: var(--robotoBold);
    letter-spacing: 0.12px
}
.website-card {
    padding: 2.25rem;
 }
 .select-overlap{
 z-index: 999;
 }
 .multi-brand-control-margin{
    margin-left: 30px;
}
.multi-brand-control-padding{
    padding-right: 10px;
}
.drop-up > .d-flex {
    flex-direction: row !important;
   
  }
  .drop-up > .d-flex > .align-items-center {
    margin-top: -7px;
  }
  .drop-up > .d-flex > .mb-1 {
    margin-right: 3px;
    color:#212529;
    font-size: 17px!important;
  }
    