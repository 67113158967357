.image-view {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 5px;

    .image-placeholder {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        background-color: #dbdbdb;
        border-radius: 5px;
        
        i {
            font-size: 2.75rem;
            color: #959595;
        }
    }
}