.tactic-container {
    background-color: #fafafa;

    title-style {
        font-size: 20px;
        font-family: var(--robotoBold);
        color: var(--brandPrimaryColor);
    }

    .tactic-title {
        @extend title-style;
    }

    .tactic-title-input {
        @extend title-style;
        background-color: transparent;
        border-radius: 2px;
    }
}