.overview {
    .overview-header {
        font-size: 16px;
        font-weight: bold;
        font-family: var(--robotoBold);
        margin-bottom: 0.75rem;
    }

    .plan-progress {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        justify-content: center;

        .progress {
            .progress-bar {
                &.bg-success {
                    background-color: var(--info) !important;
                }

                &.bg-warning {
                    background-color: var(--orange) !important;
                }

                &.bg-danger {
                    background-color: var(--danger) !important;
                }
            }
        }
    }

    .plan-counters {
        display: flex;
        justify-content: center;
        column-gap: 1rem;
        
        .plan-counter {
            display: flex;
            flex-direction: column;
            align-items: center;
            column-gap: 0.5rem;
            font-size: 12px;

            .dot {
                height: 4px;
                width: 26px;
                min-height: 4px;
                min-width: 26px;
                border-radius: 50px;
            }

            &.approved {
                .dot {
                    background-color: var(--info);
                }
            }

            &.submitted {
                .dot {
                    background-color: var(--orange);
                }
            }

            &.pendings {
                .dot {
                    background-color: var(--danger);
                }
            }
            
            &.unsubmitted {
                .dot {
                    background-color: #e0e0e0;
                }
            }

            .amount {
                font-size: 16px;
                font-weight: bold;
                font-family: var(--robotoBold);
            }
        }
    }
}