.page-item {
  font-size: .7rem;
  .page-link {
    color: var(--brandPrimaryColor);
  }

  &.active {
    .page-link {
      background-color: var(--brandPrimaryColor);
      color: white;
    }
  }
}
