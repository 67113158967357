.mailing-plan-reject-modal {
    .modal-dialog {
        max-width: 50% !important;
        height: 80% !important;
    }

    .modal-header {
        justify-content: flex-end;
        border-bottom: none;

        i {
            font-size: 2.5rem;
            top: 2rem;
            right: 2rem;
            color: #585858;
            cursor: pointer;
        }
    }

    .feedback-icon {
        &.danger {
            color: var(--danger);
        }

        &.success {
            color: var(--success);
        }

        font-size: 128px;
        margin-bottom: 1.25rem;
    }

    .feedback-title {
        font-size: 18px;
        margin: 1rem 0;
    }

    .feedback-msg {
        margin: 1rem 4rem;
    }
}
