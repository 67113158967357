.zip-code {
    font-size: 12px;
    font-weight: bold;
    font-family: var(--robotoBold);
}

.zip-code-address-icon {
    font-size: 11px;
    color: #9e9e9e;
}

.zip-code-address-text {
    font-size: 11px;
    color: #585858;
}