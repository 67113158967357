.nav-button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 12px;
    font-weight: bold;
    font-family: var(--robotoBold);
    letter-spacing: 0.12px;
    background-color: white;
    border-radius: 5px;
    padding: 0.75rem 1rem;
    box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.1);
    text-decoration: none !important;
    color: black;
    width: 100%;
    max-width: 200px;
    min-width: min-content;

    &:hover {
        text-decoration: none !important;
        color: black !important;
    }

    i {
        color: white;
        font-size: 12px;
        padding: 0.5rem;
        border-radius: 3px;
        background-color: var(--brandPrimaryColor);
    }

    .nav-button-title {
        font-weight: bold;
        font-family: var(--robotoBold);
        margin-left: 0.75rem;
    }
}