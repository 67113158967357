.mailing-plan-reject-confirmation {
    .modal-dialog {
        max-width: 50% !important;
        height: 80% !important;
    }

    .modal-header {
        display: flex;
        justify-content: center;
        align-items: baseline;
        position: relative;
        height: 100px;
        border-bottom: none;

        i {
            position: absolute;
            font-size: 2.5rem;
            top: 2rem;
            right: 2rem;
            color: #585858;
            cursor: pointer;
        }
    }

    .modal-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #dee2e6;
        border-top: 1px solid #dee2e6;
        margin: 0 3rem;
        padding: 2rem 0;
    }

    .modal-footer {
        border-top: none;
    }

    .modal-title {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        height: 100%;
        max-width: 80%;
        flex-grow: 1;
        text-align: center;
        font-size: 24px;
        font-family: var(--robotoBold);
    }

    .comment-box {
        width: 80%;
        max-width: 90%;
        height: 100%;
        min-height: 80px;
        max-height: 500px;
        border: 1px solid lightgray;
    }
}