.territories-tabs {
    .nav.nav-tabs 
    {
        align-items: flex-end;
    }

    .tabs-actions
    {
        display: flex;
        justify-content: flex-end;

        .prev-button {margin-right: 5px; }
    }

    .nav-item.nav-link {
        position: relative;
        padding: 0.5rem 0.75rem;
        min-width: 180px;
        max-height: 40px;
        color: var(--textGray);
        border-top-left-radius: 0.75rem;
        border-top-right-radius: 0.75rem;
        border: 1px solid #dee2e6;
        background-color: #f5f5f5;

        .territory-tab-name {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 15px;
            font-weight: 500;
            
            .text-overflow
            {
                max-width: 170px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                margin-bottom: 0 !important;
            }

            .territory-tab-underline {
                display: none;
                position: absolute;
                bottom: -9px;
                left: -12px;
                right: 0;
                width: 165px;
                background: white;
                height: 5px;
                padding: 0 0.75rem;

                &:after {
                    position: absolute;
                    background-color: var(--brandPrimaryColor);
                    height: 4px;
                    content: '';
                    width: 88%;
                    bottom: 0;
                    border-radius: 1rem;
                }
            }
        }

        &.active {
            background-color: white;
            max-height: 50px;
            max-width: 200px;

            .territory-tab-name {
                font-size: 21px;
                font-family: var(--robotoBold);
                color: var(--brandPrimaryColor);

                .territory-tab-underline {
                    display: block;
                }
            }
        }
    }
}