.mailing-plan-layout {
    display: grid;
    grid-template: auto / 4fr 2fr;
    padding: 0 1rem;
    column-gap: 1.5rem;
    row-gap: 1.5rem;
}

.mailing-plan-header {
    grid-column: 1 / 3;
}

.mailing-plan-content {
    grid-column: 1 / 2;
}

.mailing-plan-side {
    grid-column: 2 / 3;
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
}

.two-elements {
    flex-flow: row;
    justify-content: space-between;
    padding: 0 12px;
}