.plan-exports-item {
    background-color: white;
    border-radius: 5px;
    padding: 1rem 0.75rem;
    margin: 0.75rem 0;

    .header-name {
        font-size: 16px;
        font-weight: bold;
        font-family: var(--robotoBold);
        color: #565744;
    }

    .header-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.25rem 0.5rem;
        color: var(--brandPrimaryColor);
        border: 1px solid var(--brandPrimaryColor);
        border-radius: 5px;
    }
    
    .header-date {
        font-size: 20px;
        font-weight: bold;
        font-family: var(--robotoBold);
    }
}