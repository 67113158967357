.yearly-plan-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: black;
    min-width: 150px;
    max-width: 150px;
    min-height: 70px;
    max-height: 70px;
    text-align: center;
    font-family: var(--robotoBold);
    background-color: white;
    padding: 0.75rem 1.5rem;
    border-radius: 5px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

    &.active {
        box-shadow: 0 2px 4px 0 var(--brandPrimaryColor);
    }

    small {
        color: var(--brandPrimaryColor);
        font-family: var(--robotoBold);
    }

    &:hover {
        cursor: pointer;
        text-decoration: none;
        color: black;
    }
}