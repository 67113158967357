.input-progress {
    width: 25%;
    height: 8px;
    margin-bottom: 4px;

    .progress-bar {
        background-color: var(--brandDetailColor) !important;
    }
}

.button-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: fit-content;

    i {
        margin-left: 0.5rem;
    }
}

.input-icon-margin {
    margin-left: 50px;
}