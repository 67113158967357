.icon-btn {
  background: none;
  border: none;
  color: var(--brandPrimaryColor);

  &:hover {
    color: white;
    background-color: var(--brandPrimaryColor);
  }
}

#remove-franchise-website {
  color: white;
}

#add-website {
  background-color: var(--brandPrimaryColor);
  color: white;
  border-radius: 50%;
  height: 50px;
  width: 50px;
}
