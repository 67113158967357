.switch {
    font-size: 12px !important;

    .custom-control-label {
        .switch-label {
            position: relative;
            top: 3px;
            font-size: 12px !important;
        }
    }
}

.custom-control-input:disabled:checked~.custom-control-label::before {
    border-color: var(--disabled) !important;
    background-color: var(--disabled) !important;
}

.custom-control-input:checked~.custom-control-label::before {
    border-color: var(--brandPrimaryColor);
    background-color: var(--brandPrimaryColor);
}