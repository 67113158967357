.config-card-container {
    position: relative;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

    &:hover {
        .config-card-actions {
            display: flex !important;
            background-color: rgba(22, 22, 22, 0.55);
        }
    }

    .removal-mark {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        padding: 0.5rem;
        border-radius: 50%;
        background-color: rgba(22, 22, 22, 0.85);
        z-index: 1;
    }

    .config-card-actions {
        display: none;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        column-gap: 1rem;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .config-card-title {
        font-size: 18px;
        font-family: var(--robotoBold);
        color: white;
    }

    .config-card-action {
        font-size: 12px;
        padding: 0.5rem;
        color: white;
        border-radius: 50%;
        background-color: var(--brandPrimaryColor);
        cursor: pointer;
    }
}