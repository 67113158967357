.budget-plan-historic-label {
    margin-left: 30px;
}

.budget-plan-historic-container {
    cursor: pointer;
    position: relative;
    width: 100%;

    &:focus {
        .budget-plan-historic-options {
            width: 100% !important;
            display: block !important;
            position: absolute;
            background-color: white;
            border: 1px solid #ced4da;
            border-radius: 0.25rem;
            z-index: 100;
            max-height: 250px;
            overflow: hidden;
            overflow-y: scroll;
        }
    }

    .budget-plan-historic-value {
        display: flex;
        align-items: center;
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 1.5rem 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        i {
            position: absolute;
            right: 10px;
        }

        .budget-plan-historic-option {
            width: 100%;
            height: fit-content;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: space-between;
            column-gap: 0.5rem;
            row-gap: 0.5rem;
            margin-right: 0.5rem;

            .budget-plan-historic-option-date {
                font-size: 15px;
            }
        }
    }

    .budget-plan-historic-options {
        display: none;
        position: absolute;

        .budget-plan-historic-option {
            width: 100%;
            height: fit-content;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: space-between;
            column-gap: 0.5rem;
            row-gap: 0.5rem;
            padding: 0.5rem 1.5rem;

            &:hover {
                cursor: pointer;
                background-color: var(--brandPrimaryColor03);
            }
        }
    }

    .budget-plan-historic-option-status {
        text-transform: uppercase;
        font-size: 16px;

        &.not-submitted {
            color: #ffc107;
        }

        &.submitted {
            color: #71b4d3;
        }

        &.rejected {
            color: #ff581c;
        }

        &.approved {
            color: #a2ad00;
        }
    }

    .budget-plan-historic-option-date {
        font-size: 14px;
    }
}
