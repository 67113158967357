.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
    padding: 1rem;
    border-left: 3px solid var(--brandPrimaryColor);
    position: sticky;
    top: 0;
    z-index: 20;

    .notifications-container {
        position: absolute;
        top: 15%;
        bottom: 0;
        right: 0;

        .notification-popup {
            position: static;
        }
    }

    .header-title {
        color: var(--brandPrimaryColor);
        font-weight: 900;
        font-family: var(--robotoBold);
        font-size: 18px;
        text-transform: uppercase;
    }
    
    .header-back-btn {
        display: flex;
        align-items: center;
        column-gap: 0.75rem;
        cursor: pointer;
        text-decoration: none;

        i {
            padding: 0.25rem;
            color: var(--orange);
            border: 1px solid var(--orange);
            border-radius: 4px;
            font-size: 14px;
        }

        i:hover {
            color: white;
            background-color: var(--orange);
        }
    }

    .user-info {
        display: flex;
        align-items: center;
        border-right: 1px solid #dee2e6;
        padding-right: 2rem;
        font-size: 11px;

        .user-info-name {
            font-size: 11px;
            font-weight: bold;
            font-family: var(--robotoBold);
        }

        .user-info-franchise {
            font-size: 9px;
            font-weight: bold;
            font-family: var(--robotoBold);
        }
    }

    div.text-btn
    {
        border-radius: 0;
        padding-right: 2rem;
        border-right: 1px solid #dee2e6;

        img.icon
        {
            width: 10px;
            margin-right: 0.5rem;
        }
    }

    .logout {
        display: flex;
        align-items: center;
        padding: 0 1rem;
        font-size: 11px;
        font-weight: bold;
        color: black;
        text-decoration: none;

        &:hover {
            cursor: pointer;
            color: var(--brandPrimaryColor);
        }
    }
}