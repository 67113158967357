.mailing-plan-details-modal {
    .modal-dialog {
        max-width: 80% !important;
        height: 80% !important;
    }

    .modal-header {
        display: flex;
        justify-content: center;
        align-items: baseline;
        position: relative;
        height: 100px;
        border-bottom: none;

        i {
            position: absolute;
            font-size: 2.5rem;
            top: 2rem;
            right: 2rem;
            color: #585858;
            cursor: pointer;
        }
    }

    .modal-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #dee2e6;
        border-top: 1px solid #dee2e6;
        margin: 0 3rem;
        padding: 2rem 0;
    }

    .modal-footer {
        border-top: none;
    }

    .modal-title {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        height: 100%;
        max-width: 80%;
        flex-grow: 1;
        text-align: center;
        font-size: 24px;
        font-family: var(--robotoBold);
    }

    .comment-box {
        width: 80%;
        max-width: 90%;
        height: 100%;
        min-height: 80px;
        border: 1px solid lightgray;
    }

    .progress-bar {
        background-color: var(--brandPrimaryColor) !important;   
    }

    .information-upload-options {
        display: flex;
        justify-content: space-evenly;
        width: 100%;
        max-width: 800px;
    }

    .mailing-plan-link {
        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }

        color: var(--linkColor);
    }

    .mailing-plan-details-campaigns-table {
        table {
            thead {
                color: white;
                background-color: #575757;
    
                th {
                    font-size: 12px;
                    font-weight: 500;
                    -webkit-font-smoothing: antialiased;
                    -webkit-font-smoothing: subpixel-antialiased;
                    font-family: "Roboto-Regular", system-ui, sans-serif;
                }
            }
    
            tbody td {
                font-size: 12px;
                font-weight: 500;
                -webkit-font-smoothing: antialiased;
                -webkit-font-smoothing: subpixel-antialiased;
                font-family: "Roboto-Regular", system-ui, sans-serif;
    
                .create-plan-btn {
                    font-size: 11px;
                    color: var(--brandPrimaryColor);
                    background-color: white;
                    border: 1px solid var(--brandPrimaryColor);
                    border-radius: 15px;
                    padding: 8px 16px;
                    
                    &:hover {
                        color: white;
                        background-color: var(--brandPrimaryColor);
                        text-decoration: none;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}