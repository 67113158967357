:root {
    --robotoBold: "Roboto-Bold", system-ui, sans-serif;
    --directMailPrimaryColor: #1e9b8c;
    --brandPrimaryColor: #1e9b8c;
    --brandSecondaryColor: #1e9b8c;
    --brandDetailColor: #34c1ff;
    --brandPrimaryColor03: #1e9b8c30;
    --brandSecondaryColor03: #1e9b8c30;
    --brandDetailColor03: #34c1ff30;
    --backgroundColor: #efeaed;
    --disabled: #a3a3a3;
    --textGray: #585858;
    --linkColor: #34c1ff;
    --orange: #fd681d;
    --info: #38beaf;
    --success: #39b557;
    --danger: #dc2e2e;
    --warning: #ff9800;
    --neutral: #6c757d;
    --primary: #007bff;
}