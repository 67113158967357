.campaigns-list-item {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    background-color: white;
    padding: 1rem;
    margin-bottom: 1rem;
    border: 1px solid #dee2e6;
    border-radius: 4px;

    &.selected {
        border-color: var(--brandDetailColor);
    }

    .item-details {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        column-gap: 0.5rem;
        margin-bottom: 0.25rem;
    }

    .item-config {
        display: flex;
        justify-content: center;
        column-gap: 1rem;
        padding-top: 0.75rem;
    }
}

.campaign-name {
    font-size: 12px;
    font-weight: bold;
    font-family: var(--robotoBold);
    color: var(--brandPrimaryColor);
}

.campaign-date {
    font-size: 14px;
    font-weight: bold;
    font-family: var(--robotoBold);
}

.campaign-brochure {
    width: 85px;
}

.campaign-brochure-img {
    height: 85px;
    width: 85px;
}