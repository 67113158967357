.plan-reports-tabs {
    .tab-content {
        margin-top: 1rem;
    }

    .nav-item.nav-link {
        padding: 0.75rem;
        font-size: 14px;
        min-width: 200px;
        text-align: center;
        color: var(--textGray);
        font-family: var(--robotoBold);
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
        border: 1px solid #dee2e6;

        &.active {
            color: var(--brandPrimaryColor);
            border: 1px solid var(--brandPrimaryColor);
        }
    }
}