.services-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    column-gap: 1rem;
    grid-gap: 1rem;
    max-height: 400px;
    overflow: auto;
}

.service-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    width: 230px;
    cursor: pointer;
    font-size: 16px;
    color: #818181;
    font-weight: bold;
    font-family: var(--robotoBold);

    &.selected {
        cursor: pointer !important;
        border: 2px solid var(--brandPrimaryColor) !important;
        color: var(--brandPrimaryColor);
    }

    &.disabled { 
        cursor: default;
    }
}

.services-counter {
    text-align: center;
    font-size: 16px;
    color: #818181;
    font-weight: bold;
    font-family: var(--robotoBold);
    margin-bottom: 1.5rem;

    &.completed {
        color: #fd681d;
    }
}