.icon-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--brandPrimaryColor);
    background-color: transparent;
    border-radius: 5px;
    min-width: 36px;
    min-height: 36px;
    max-width: 36px;
    max-height: 36px;
    
    i {
        font-size: 16px;
    }

    &.disabled {
        color: var(--disabled) !important;
        border: 1px solid var(--disabled) !important;

        &:hover {
            color: white !important;
            background-color: var(--disabled) !important;
        }
    }

    &.primary {
        color: var(--brandPrimaryColor);
        border: 1px solid var(--brandPrimaryColor);

        &:hover {
            color: white;
            background-color: var(--brandPrimaryColor);
        }
    }

    &.danger {
        color: var(--danger);
        border: 1px solid var(--danger);

        &:hover {
            color: white;
            background-color: var(--danger);
        }
    }

    &.warning {
        color: var(--orange);
        border: 1px solid var(--orange);

        &:hover {
            color: white;
            background-color: var(--orange);
        }
    }

    &.square {
        border-radius: 5px !important;
    }

    &.circle {
        border-radius: 50% !important;
    }
}