.review-budget-plan-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0px;

    th {
        font-size: 14px;
        font-family: var(--robotoBold);
        color: var(--neutral);
    }

    td {
        height: 45px;
        max-width: 105px;
        border: 1px solid #cecece;
        font-size: 15px;
        font-family: var(--robotoRegular);
        text-overflow: ellipsis;
        overflow: hidden;
        border-right: none;
        padding: 0 1rem;

        &:first-child {
            color: #4ecff6;
            border-bottom-left-radius: 0.5rem;
            border-top-left-radius: 0.5rem;
        }

        &:last-child {
            border-bottom-right-radius: 0.5rem;
            border-top-right-radius: 0.5rem;
            border-right: 1px solid #cecece;
        }
    }
}

.review-budget-plan-label {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 50px;
    font-size: 2rem;
    user-select: none;

    &:hover {
        background-color: #f2f2f2;
        text-decoration: none;
        color: var(--brandPrimaryColor);
    }
}
